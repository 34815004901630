"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCameraConstraints = void 0;
// https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Constraints#overview
// When "advanced" constraints are given, user agent will try to satisfy each till one is successful.
// So in this case it will start with trying first item in the array and keep trying till one is satisfied.
// See more about advanced constraints: https://www.w3.org/TR/mediacapture-streams/#example-3
var CAMERA_ADV_RES_CONSTRAINTS = [
    { height: 2560, width: 3840 },
    { height: 1920, width: 2880 },
    { height: 1536, width: 2304 },
    { height: 1280, width: 1920 },
    { height: 960, width: 1440 },
    { height: 768, width: 1152 },
    { height: 720, width: 1080 },
];
// Same as above but this one only uses height and aspectRatio. In this case user agent will set corresponding width to satisfy given height with aspectRatio.
// 1.5 is 3:2 aspect ratio.
var CAMERA_ADV_RATIO_CONSTRAINTS = [
    { width: 3840, aspectRatio: 1.5 },
    { width: 2880, aspectRatio: 1.5 },
    { width: 2304, aspectRatio: 1.5 },
    { width: 1920, aspectRatio: 1.5 },
    { width: 1440, aspectRatio: 1.5 },
    { width: 1152, aspectRatio: 1.5 },
    { width: 1080, aspectRatio: 1.5 },
];
var CAMERA_CONSTRAINTS = {
    facingMode: 'environment',
    width: { min: 1080, ideal: 2880, max: 3840 },
    frameRate: 30,
    aspectRatio: 1.5,
    advanced: CAMERA_ADV_RES_CONSTRAINTS,
};
//! Known issue in Android Firefox.
// With some Android devices Firefox fail to adjust the orientation of the camera when device orientation change.
// https://bugzilla.mozilla.org/show_bug.cgi?id=874569. With or without any constraints except the facingMode,
// the behaviour is still same for same devices - so it's not constraint related.
function getCameraConstraints() {
    var mediaDevices = navigator.mediaDevices;
    var SUPPORTED_CONSTRAINTS = mediaDevices.getSupportedConstraints();
    // Aspect Ratio constraints is not supported for all browsers.
    // If it's supported then we will use RATIO_CONSTRAINTS instead of RES_CONSTRAINTS.
    // Ex. Firefox Android does not support aspectRatio constraint - in this case { width, height } will be used.
    if ('aspectRatio' in SUPPORTED_CONSTRAINTS) {
        CAMERA_CONSTRAINTS.advanced = CAMERA_ADV_RATIO_CONSTRAINTS;
    }
    return CAMERA_CONSTRAINTS;
}
exports.getCameraConstraints = getCameraConstraints;
