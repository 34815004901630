"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var photo_studio_types_1 = require("@gql_codegen/photo-studio-types");
var antd_1 = require("antd");
var CarInfo_1 = require("@components/CarInfo");
var icons_1 = require("@ant-design/icons");
var react_1 = require("react");
var getServerErrors_1 = require("@utils/getServerErrors");
var dataQaSelectors_1 = require("@src/dataQaSelectors");
var PendingCars_1 = require("@components/PendingCars");
var layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8, lg: { span: 8 } },
};
var tailLayout = {
    wrapperCol: { align: 'center', span: 24 },
};
var SearchPage = function () {
    var _a;
    var form = antd_1.Form.useForm()[0];
    var _b = (0, react_1.useState)(null), carData = _b[0], setCarData = _b[1];
    var _c = (0, react_1.useState)(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = (0, photo_studio_types_1.useGetPendingCarsQuery)(), data = _d.data, isPendingCarsLoading = _d.isLoading;
    var onFinish = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var dataFetcher, data_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dataFetcher = photo_studio_types_1.useGetCarDetailsQuery.fetcher({
                        input: { searchText: value.searchText },
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, dataFetcher()];
                case 2:
                    data_1 = _a.sent();
                    setCarData(data_1.getCarDetails);
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    antd_1.notification.error({
                        message: (0, getServerErrors_1.getServerErrors)(e_1),
                    });
                    setCarData(null);
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onReset = function () {
        form.resetFields();
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(antd_1.Form, __assign({}, layout, { form: form, name: "search-form", onFinish: onFinish, "data-qa-selector": dataQaSelectors_1.DataQaSelector.searchForm, children: [(0, jsx_runtime_1.jsx)(antd_1.Form.Item, { name: "searchText", label: "VIN or Stock Number", rules: [{ required: true }], validateTrigger: "onSubmit", children: (0, jsx_runtime_1.jsx)(antd_1.Input, { allowClear: true, onReset: onReset, size: "large", type: "text", autoCapitalize: "characters", "data-qa-selector": dataQaSelectors_1.DataQaSelector.searchInput }) }), (0, jsx_runtime_1.jsx)(antd_1.Form.Item, __assign({}, tailLayout, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, { type: "primary", htmlType: "submit", icon: (0, jsx_runtime_1.jsx)(icons_1.SearchOutlined, {}), size: "large", "data-qa-selector": dataQaSelectors_1.DataQaSelector.searchButton, children: "Search" }) }))] })), isLoading ? ((0, jsx_runtime_1.jsx)(antd_1.Spin, { size: "large", "data-qa-selector": dataQaSelectors_1.DataQaSelector.loadingSpiner })) : null, carData && (0, jsx_runtime_1.jsx)(CarInfo_1.CarInfo, { carDetails: carData }), (0, jsx_runtime_1.jsx)(PendingCars_1.PendingCars, { items: (_a = data === null || data === void 0 ? void 0 : data.getPendingCars) !== null && _a !== void 0 ? _a : [], isLoading: isPendingCarsLoading })] }));
};
exports.SearchPage = SearchPage;
