// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vqkstx_ulp0lzZspVuqL {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  margin-bottom: 25px;
}
.fHvr8CJrKxcXITAo68Un {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ffa500;
  border-radius: 5px;
  aspect-ratio: 3/2;
}
.PkKmopByYdg7c0iJZQzm {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.AGO08bSOWsG6jxB_pP0P {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/HighlightsItem/styles.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EAEA,gBAAA;EACA,mBAAA;AADF;AAIA;EACE,kBAAA;EAEA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EAEA,eAAA;EACA,kBAAA;EAEA,yBAAA;EACA,kBAAA;EACA,iBAAA;AALF;AAQA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AANF;AASA;EACE,WAAA;AAPF","sourcesContent":["@antVersion: antd-5-13-2;\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  min-height: 100%;\n  margin-bottom: 25px;\n}\n\n.imageWrapper {\n  position: relative;\n\n  display: flex;\n  overflow: hidden;\n  align-items: center;\n  justify-content: center;\n\n  cursor: pointer;\n  text-align: center;\n\n  border: 1px solid #ffa500;\n  border-radius: 5px;\n  aspect-ratio: 3/2;\n}\n\n.deleteIcton {\n  position: absolute;\n  right: 10px;\n  bottom: 10px;\n}\n\n.image {\n  width: 100%;\n}\n\n@ant-prefix: antd-5-13-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Vqkstx_ulp0lzZspVuqL`,
	"imageWrapper": `fHvr8CJrKxcXITAo68Un`,
	"deleteIcton": `PkKmopByYdg7c0iJZQzm`,
	"image": `AGO08bSOWsG6jxB_pP0P`
};
export default ___CSS_LOADER_EXPORT___;
