// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awxD8WMUZY7PYsF7BTbW {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  margin-bottom: 25px;
}
.aMLdmiFKnsoV1gxYnAqg {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ffa500;
  border-radius: 5px;
  aspect-ratio: 3/2;
}
.GnqjPC8tTbZrFGl6QRmB {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.iti_zJaaHeRMxMWopKDG {
  width: 100%;
}
.ZM7NvzE3qhrn_4ByKY9_ {
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InteriorExteriorItem/styles.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EAEA,gBAAA;EACA,mBAAA;AADF;AAIA;EACE,kBAAA;EAEA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EAEA,eAAA;EACA,kBAAA;EAEA,yBAAA;EACA,kBAAA;EACA,iBAAA;AALF;AAQA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AANF;AASA;EACE,WAAA;AAPF;AAUA;EACE,kBAAA;AARF","sourcesContent":["@antVersion: antd-5-13-2;\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  min-height: 100%;\n  margin-bottom: 25px;\n}\n\n.imageWrapper {\n  position: relative;\n\n  display: flex;\n  overflow: hidden;\n  align-items: center;\n  justify-content: center;\n\n  cursor: pointer;\n  text-align: center;\n\n  border: 1px solid #ffa500;\n  border-radius: 5px;\n  aspect-ratio: 3/2;\n}\n\n.deleteIcton {\n  position: absolute;\n  right: 10px;\n  bottom: 10px;\n}\n\n.image {\n  width: 100%;\n}\n\n.title {\n  margin-bottom: 5px;\n}\n\n@ant-prefix: antd-5-13-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `awxD8WMUZY7PYsF7BTbW`,
	"imageWrapper": `aMLdmiFKnsoV1gxYnAqg`,
	"deleteIcton": `GnqjPC8tTbZrFGl6QRmB`,
	"image": `iti_zJaaHeRMxMWopKDG`,
	"title": `ZM7NvzE3qhrn_4ByKY9_`
};
export default ___CSS_LOADER_EXPORT___;
