"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraDrawer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
// import { Helmet } from 'react-helmet';
var antd_1 = require("antd");
var store_1 = require("../../store");
var useOrientation_1 = require("./useOrientation");
var OrientationWarning_1 = require("./OrientationWarning");
var CameraView_1 = require("./CameraView");
var dataQaSelectors_1 = require("@src/dataQaSelectors");
var CameraDrawer = function () {
    var isCameraDrawerOpen = (0, store_1.useGalleryStore)(function (state) { return [
        state.isCameraDrawerOpen,
    ]; })[0];
    var orientation = (0, useOrientation_1.useDeviceOrientation)();
    return ((0, jsx_runtime_1.jsx)(antd_1.Drawer, { destroyOnClose: true, placement: 'top', closeIcon: false, open: isCameraDrawerOpen, height: '100vh', zIndex: 1100, keyboard: false, styles: { body: { backgroundColor: '#000', padding: '0 24px' } }, "data-qa-selector": dataQaSelectors_1.DataQaSelector.cameraDrawer, children: orientation !== 'landscape' ? (0, jsx_runtime_1.jsx)(OrientationWarning_1.OrientationWarning, {}) : (0, jsx_runtime_1.jsx)(CameraView_1.CameraView, {}) }));
};
exports.CameraDrawer = CameraDrawer;
