"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceOrientation = void 0;
var react_1 = require("react");
var mapScreenOrientation = function (screenOrientation) {
    switch (screenOrientation) {
        case 'landscape-primary':
        case 'landscape-secondary':
            return 'landscape';
        case 'portrait-primary':
        case 'portrait-secondary':
            return 'portrait';
        default:
            return 'unknown';
    }
};
var getDeviceOrientation = function () {
    if (typeof window === 'undefined') {
        return 'unknown';
    }
    try {
        return mapScreenOrientation(window.screen.orientation.type);
    }
    catch (_) {
        // window.screen.orientation.type is not supported in all browsers
        return window.matchMedia('(orientation: landscape)').matches
            ? 'landscape'
            : 'portrait';
    }
};
var subscribeToOrientaionChange = function (handler) {
    var mediaQuery = window.matchMedia('(orientation: landscape)');
    try {
        mediaQuery.addEventListener('change', handler);
        return function () {
            mediaQuery.removeEventListener('change', handler);
        };
    }
    catch (_) {
        // in older browsers `MediaQueryList` is not inherited from `EventTarget`
        mediaQuery.addListener(handler);
        return function () {
            mediaQuery.removeListener(handler);
        };
    }
};
var useDeviceOrientation = function (onChange) {
    var _a = (0, react_1.useState)(getDeviceOrientation()), orientation = _a[0], setOrientation = _a[1];
    (0, react_1.useEffect)(function () {
        var handleMediaChange = function (evt) {
            var newValue = evt.matches ? 'landscape' : 'portrait';
            setOrientation(function (prevValue) {
                if (onChange && prevValue !== newValue) {
                    onChange(prevValue, newValue);
                }
                return newValue;
            });
        };
        var unsubscribe = subscribeToOrientaionChange(handleMediaChange);
        return function () {
            unsubscribe();
        };
    }, [onChange]);
    return orientation;
};
exports.useDeviceOrientation = useDeviceOrientation;
