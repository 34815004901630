"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGalleryStore = void 0;
var zustand_1 = require("zustand");
exports.useGalleryStore = (0, zustand_1.create)(function (set) { return ({
    INTERIOR: new Map(),
    EXTERIOR: new Map(),
    HIGHLIGHTS: new Map(),
    isCameraDrawerOpen: false,
    isOrientationWarningOpen: false,
    currentImage: null,
    cameraDeviceId: null,
    setCurrentImage: function (img) { return set(function () { return ({ currentImage: img }); }); },
    toggleCameraDrawer: function (status) { return set(function () { return ({ isCameraDrawerOpen: status }); }); },
    isPermissionGranted: null,
}); });
