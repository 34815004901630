// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LfCbsbgwe0ghxzZf6RCJ {
  min-height: 100vh;
  padding: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/styles.less"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,aAAA;AAAF","sourcesContent":["@antVersion: antd-5-13-2;\n.root {\n  min-height: 100vh;\n  padding: 15px;\n}\n\n@ant-prefix: antd-5-13-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `LfCbsbgwe0ghxzZf6RCJ`
};
export default ___CSS_LOADER_EXPORT___;
