"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataQaSelector = void 0;
exports.DataQaSelector = {
    mainLayout: 'mainLayout',
    mainHeading: 'mainHeading',
    searchForm: 'searchForm',
    searchInput: 'searchInput',
    searchButton: 'searchButton',
    loadingSpiner: 'loadingSpiner',
    collapseSection: 'collapseSection',
    goToGalleryButton: 'goToGalleryButton',
    goToGalleryLink: 'goToGalleryLink',
    carInfoCard: 'carInfoCard',
    carInfoTitle: 'carInfoTitle',
    carInfoVin: 'carInfoVin',
    carInfoStock: 'carInfoStock',
    carInfoColor: 'carInfoColor',
    carInfoBuiltYear: 'carInfoBuiltYear',
    carInfoGearType: 'carInfoGearType',
    addImageItem: 'addImageItem',
    exteriorTitle: 'exteriorTitle',
    interiorTitle: 'interiorTitle',
    highlightsTitle: 'highlightsTitle',
    galleryItemWrapper: 'galleryItemWrapper',
    galleryItemImageWrapper: 'galleryItemImageWrapper',
    galleryImage: 'galleryImage',
    deleteImageButton: 'deleteImageButton',
    galleryItemTitle: 'galleryItemTitle',
    galleryPlaceholderIcon: 'galleryPlaceholderIcon',
    cameraDrawer: 'cameraDrawer',
    shutterButton: 'shutterButton',
    videoElement: 'videoElement',
    closeCameraButton: 'closeCameraButton',
    hintsPopover: 'hintsPopover',
    hintsList: 'hintsList',
    hintsItem: 'hintsItem',
    overlay: 'overlay',
    preview: 'preview',
    goToSearchLink: 'goToSearchLink',
    pendingCar: 'pendingCar',
    pendingCarsList: 'pendingCarsList',
    pendingCarsTitle: 'pendingCarsTitle',
};
