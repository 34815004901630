"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendingCars = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var wouter_1 = require("wouter");
var dataQaSelectors_1 = require("@src/dataQaSelectors");
var constants_1 = require("@src/constants");
var PendingCars = function (_a) {
    var items = _a.items, isLoading = _a.isLoading;
    var _b = (0, wouter_1.useLocation)(), setLocation = _b[1];
    var goToGallery = function (stockNumber) {
        setLocation("".concat(constants_1.BASE_ROUTE, "/").concat(stockNumber, "/gallery"));
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(antd_1.Typography.Title, { level: 3, style: { margin: 20, textAlign: 'center', color: '#0d4f78' }, "data-qa-selector": dataQaSelectors_1.DataQaSelector.pendingCarsTitle, children: "Pending cars:" }), isLoading ? ((0, jsx_runtime_1.jsx)(antd_1.Spin, { size: "large", "data-qa-selector": dataQaSelectors_1.DataQaSelector.loadingSpiner })) : ((0, jsx_runtime_1.jsx)(antd_1.Row, { justify: 'center', children: (0, jsx_runtime_1.jsx)(antd_1.Col, { lg: 8, xs: 24, md: 16, children: items.length > 0 ? ((0, jsx_runtime_1.jsx)(antd_1.List, { itemLayout: "horizontal", bordered: true, dataSource: items, "data-qa-selector": dataQaSelectors_1.DataQaSelector.pendingCarsList, renderItem: function (item) { return ((0, jsx_runtime_1.jsx)(antd_1.List.Item, { onClick: function () {
                                goToGallery(item.stockNumber);
                            }, "data-qa-selector": dataQaSelectors_1.DataQaSelector.pendingCar, children: (0, jsx_runtime_1.jsx)(antd_1.List.Item.Meta, { title: (0, jsx_runtime_1.jsx)(antd_1.Typography.Title, { level: 5, children: "".concat(item.make, " ").concat(item.model) }), style: { cursor: 'pointer' }, description: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["VIN: ".concat(item.vin), (0, jsx_runtime_1.jsx)("br", {}), " ", "Stock Number: ".concat(item.stockNumber)] }) }) })); } })) : ('No pending cars yet') }) }))] }));
};
exports.PendingCars = PendingCars;
