"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVinOrStockNumber = void 0;
var react_1 = require("react");
var wouter_1 = require("wouter");
var useVinOrStockNumber = function () {
    var location = (0, wouter_1.useLocation)()[0];
    var vinOrStockNumber = (0, react_1.useMemo)(function () { var _a; return (_a = location.split('/')[3]) !== null && _a !== void 0 ? _a : ''; }, []);
    return vinOrStockNumber;
};
exports.useVinOrStockNumber = useVinOrStockNumber;
