"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCamera = exports.useInitCamera = void 0;
var react_1 = require("react");
var jslib_html5_camera_photo_1 = __importStar(require("jslib-html5-camera-photo"));
var mediaConstraints_1 = require("./mediaConstraints");
var utils_1 = require("./utils");
var store_1 = require("@src/store");
var handleCameraErrors = function (error) {
    if (error instanceof DOMException && error.name === 'NotAllowedError') {
        return 'Camera access denied. Please grant camera access permission!';
    }
    else if (error instanceof DOMException && error.name === 'NotFoundError') {
        return 'Camera not found on the current device!';
    }
    else if (error instanceof DOMException) {
        return "".concat(error.name, " ").concat(error.message);
    }
    return 'Unknown camera error';
};
var useInitCamera = function () {
    var _a = (0, react_1.useState)(false), isIniting = _a[0], setIsIniting = _a[1];
    var _b = (0, react_1.useState)(null), initError = _b[0], setInitError = _b[1];
    var initCameraDevice = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var mediaDevices, media, tracks, deviceData, backCameraId, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    // This is a dirty hack to detect available devices and choose exact Back camera
                    // on devices with multiple cameras
                    setIsIniting(true);
                    mediaDevices = navigator.mediaDevices;
                    return [4 /*yield*/, mediaDevices.getUserMedia({ video: true })];
                case 1:
                    media = _c.sent();
                    tracks = media.getVideoTracks();
                    tracks.forEach(function (t) { return t.stop(); });
                    return [4 /*yield*/, mediaDevices.enumerateDevices()];
                case 2:
                    deviceData = _c.sent();
                    backCameraId = (_b = (_a = deviceData
                        .filter(function (i) { return i.kind === 'videoinput'; })
                        .find(function (i) {
                        return i.label === 'Back Camera' ||
                            i.label === 'Rückkamera' ||
                            i.label === 'Fotocamera (posteriore)' ||
                            i.label === 'Caméra arrière' ||
                            i.label === 'Camera aan achterzijde' ||
                            i.label === 'Cámara trasera' ||
                            i.label === 'Aparat tylny' ||
                            i.label === 'Kamera på baksidan';
                    })) === null || _a === void 0 ? void 0 : _a.deviceId) !== null && _b !== void 0 ? _b : jslib_html5_camera_photo_1.FACING_MODES.ENVIRONMENT;
                    store_1.useGalleryStore.setState({
                        cameraDeviceId: backCameraId,
                        isPermissionGranted: true,
                    });
                    setIsIniting(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    setInitError(handleCameraErrors(error_1));
                    if (error_1 instanceof DOMException && error_1.name === 'NotAllowedError') {
                        store_1.useGalleryStore.setState({ isPermissionGranted: false });
                    }
                    setIsIniting(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, []);
    return {
        isIniting: isIniting,
        initError: initError,
        initCameraDevice: initCameraDevice,
    };
};
exports.useInitCamera = useInitCamera;
var useCamera = function (videoRef) {
    var _a = (0, react_1.useState)(false), isStarting = _a[0], setIsStarting = _a[1];
    var _b = (0, react_1.useState)(null), error = _b[0], setError = _b[1];
    var camera = (0, react_1.useRef)(null);
    var backCameraId = (0, store_1.useGalleryStore)(function (state) { return state.cameraDeviceId; });
    var startCamera = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!backCameraId) {
                        return [2 /*return*/, setError('No back camera found!')];
                    }
                    if (!camera.current) {
                        return [2 /*return*/, setError('Camera in not initalised!')];
                    }
                    setIsStarting(true);
                    return [4 /*yield*/, camera.current
                            .startCamera(backCameraId) // workaround: we need to pass exact deviceId but library accepts only FacingMode type
                            .then(function (res) {
                            var videoTrack = res.getVideoTracks()[0];
                            // The reason we set constraints with `applyConstraints` function is that
                            // the jslib-html5-camera-photo `startCamera` function does not handle constraints other than width and height.
                            return videoTrack.applyConstraints((0, mediaConstraints_1.getCameraConstraints)()).then(function () {
                                setIsStarting(false);
                                return res;
                            });
                        })
                            .catch(function (error) {
                            setIsStarting(false);
                            setError(handleCameraErrors(error));
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, [backCameraId]);
    var stopCamera = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, camera.current.stopCamera()];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_2 = _a.sent();
                    setError('Failed to stop camera!');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getUriData = function () {
        if (!camera.current) {
            setError('Failed to get image');
            return null;
        }
        return camera.current.getDataUri(utils_1.DEFAULT_PHOTO_CONFIG);
    };
    (0, react_1.useEffect)(function () {
        if (videoRef && videoRef.current) {
            camera.current = new jslib_html5_camera_photo_1.default(videoRef.current);
        }
    }, [videoRef]);
    return {
        error: error,
        isStarting: isStarting,
        startCamera: startCamera,
        stopCamera: stopCamera,
        getUriData: getUriData,
    };
};
exports.useCamera = useCamera;
