// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gPPL9LOaBiQiACYiwbVI {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Y3Ce5uBUEzWBwUhYKUKz {
  display: inline-block;
  margin-bottom: 24px;
  padding: 6px;
  border: 1px solid;
  border-radius: 3px;
}
.Y3Ce5uBUEzWBwUhYKUKz :hover {
  text-decoration: none !important;
}
.arJnNIwMVJXAyBehewBr {
  text-align: end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/GalleryPage/styles.less"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EAEA,gCAAA;AADF;AAIA;EACE,qBAAA;EAEA,mBAAA;EACA,YAAA;EAEA,iBAAA;EACA,kBAAA;AAJF;AAHA;EAUI,gCAAA;AAJJ;AAQA;EACE,eAAA;AANF","sourcesContent":["@antVersion: antd-5-13-2;\n.loadingSpinner {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n\n  transform: translate(-50%, -50%);\n}\n\n.goToSearchButton {\n  display: inline-block;\n\n  margin-bottom: 24px;\n  padding: 6px;\n\n  border: 1px solid;\n  border-radius: 3px;\n\n  :hover {\n    text-decoration: none !important;\n  }\n}\n\n.buttonWrapper {\n  text-align: end;\n}\n\n@ant-prefix: antd-5-13-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingSpinner": `gPPL9LOaBiQiACYiwbVI`,
	"goToSearchButton": `Y3Ce5uBUEzWBwUhYKUKz`,
	"buttonWrapper": `arJnNIwMVJXAyBehewBr`
};
export default ___CSS_LOADER_EXPORT___;
