"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var photo_studio_types_1 = require("@gql_codegen/photo-studio-types");
var antd_1 = require("antd");
var Meta = antd_1.Card.Meta;
var icons_1 = require("@ant-design/icons");
var constants_1 = require("@src/constants");
var wouter_1 = require("wouter");
var dataQaSelectors_1 = require("@src/dataQaSelectors");
var CarInfo = function (_a) {
    var _b, _c, _d, _e, _f;
    var carDetails = _a.carDetails, _g = _a.isGalleryInfo, isGalleryInfo = _g === void 0 ? false : _g;
    var actionButton = ((0, jsx_runtime_1.jsx)(antd_1.Button, { type: "default", title: "Take photos", size: "large", icon: (0, jsx_runtime_1.jsx)(icons_1.CameraOutlined, {}), "data-qa-selector": dataQaSelectors_1.DataQaSelector.goToGalleryButton, children: (0, jsx_runtime_1.jsx)(wouter_1.Link, { style: { display: 'inline' }, href: "".concat(constants_1.BASE_ROUTE, "/").concat(carDetails.vin, "/gallery"), "data-qa-selector": dataQaSelectors_1.DataQaSelector.goToGalleryLink, children: "Take photos" }) }));
    var front_left_photo = carDetails.photos.find(function (i) { return i.imageSubType === photo_studio_types_1.ImageSubType.ExteriorFrontLeft; });
    var cover = front_left_photo && front_left_photo.originalImageUrl ? ((0, jsx_runtime_1.jsx)("img", { alt: "example", src: front_left_photo.originalImageUrl })) : ((0, jsx_runtime_1.jsx)(icons_1.CarOutlined, { style: { fontSize: 100 } }));
    return ((0, jsx_runtime_1.jsx)(antd_1.Row, { justify: 'center', children: (0, jsx_runtime_1.jsx)(antd_1.Col, { children: (0, jsx_runtime_1.jsxs)(antd_1.Card, { hoverable: true, actions: isGalleryInfo ? [] : [actionButton], style: { width: '100%', maxWidth: 400, minWidth: 300 }, cover: cover, "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoCard, children: [(0, jsx_runtime_1.jsx)(Meta, { title: "".concat(carDetails.make, " ").concat(carDetails.model), "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoTitle }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)(antd_1.Typography.Paragraph, { "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoVin, children: ["VIN: ", (_b = carDetails.vin) !== null && _b !== void 0 ? _b : '-'] }), (0, jsx_runtime_1.jsxs)(antd_1.Typography.Paragraph, { "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoStock, children: ["Stock Number: ", (_c = carDetails.stockNumber) !== null && _c !== void 0 ? _c : '-'] }), (0, jsx_runtime_1.jsxs)(antd_1.Typography.Paragraph, { "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoColor, children: ["Color: ", (_d = carDetails.color) !== null && _d !== void 0 ? _d : '-'] }), (0, jsx_runtime_1.jsxs)(antd_1.Typography.Paragraph, { "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoBuiltYear, children: ["Built year: ", (_e = carDetails.builtYear) !== null && _e !== void 0 ? _e : '-'] }), (0, jsx_runtime_1.jsxs)(antd_1.Typography.Paragraph, { "data-qa-selector": dataQaSelectors_1.DataQaSelector.carInfoGearType, children: ["Gear type: ", (_f = carDetails.gearType) !== null && _f !== void 0 ? _f : '-'] })] }) }) }));
};
exports.CarInfo = CarInfo;
