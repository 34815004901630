"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoGallery = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var InteriorExteriorItem_1 = require("@components/InteriorExteriorItem");
var CameraDrawer_1 = require("@components/CameraDrawer");
var store_1 = require("../../store");
var HighlightsItem_1 = require("@components/HighlightsItem");
var AddImage_1 = require("@components/AddImage");
var dataQaSelectors_1 = require("@src/dataQaSelectors");
var photo_studio_types_1 = require("@gql_codegen/photo-studio-types");
var PhotoGallery = function () {
    var exterior = (0, store_1.useGalleryStore)(function (state) { return state.EXTERIOR; });
    var interior = (0, store_1.useGalleryStore)(function (state) { return state.INTERIOR; });
    var highlights = (0, store_1.useGalleryStore)(function (state) { return state.HIGHLIGHTS; });
    return ((0, jsx_runtime_1.jsxs)(antd_1.Space, { direction: "vertical", style: { display: 'flex' }, children: [(0, jsx_runtime_1.jsx)(antd_1.Divider, { orientation: "left", children: (0, jsx_runtime_1.jsxs)(antd_1.Typography.Title, { level: 4, style: { color: '#0d4f78', margin: 0 }, "data-qa-selector": dataQaSelectors_1.DataQaSelector.exteriorTitle, children: ["Exterior (", Array.from(exterior.values()).filter(function (i) {
                            return Boolean(i.thumbnailImageUrl);
                        }).length, ' ', "/ ", interior.size, ")"] }) }), (0, jsx_runtime_1.jsx)(antd_1.Row, { justify: 'start', gutter: 20, children: Array.from(exterior.values()).map(function (item) { return ((0, jsx_runtime_1.jsx)(antd_1.Col, { xs: 12, sm: 8, lg: 6, children: (0, jsx_runtime_1.jsx)(InteriorExteriorItem_1.InteriorExteriorImage, { imageType: photo_studio_types_1.ImageType.Exterior, imageSubType: item.imageSubType }) }, item.imageSubType)); }) }), (0, jsx_runtime_1.jsx)(antd_1.Divider, { orientation: "left", children: (0, jsx_runtime_1.jsxs)(antd_1.Typography.Title, { level: 4, style: { color: '#0d4f78', margin: 0 }, "data-qa-selector": dataQaSelectors_1.DataQaSelector.interiorTitle, children: ["Interior (", Array.from(interior.values()).filter(function (i) {
                            return Boolean(i.thumbnailImageUrl);
                        }).length, ' ', "/ ", interior.size, ")"] }) }), (0, jsx_runtime_1.jsx)(antd_1.Row, { justify: 'start', gutter: 20, children: Array.from(interior.values()).map(function (item) { return ((0, jsx_runtime_1.jsx)(antd_1.Col, { xs: 12, sm: 8, lg: 6, children: (0, jsx_runtime_1.jsx)(InteriorExteriorItem_1.InteriorExteriorImage, { imageType: photo_studio_types_1.ImageType.Interior, imageSubType: item.imageSubType }) }, item.imageSubType)); }) }), (0, jsx_runtime_1.jsx)(antd_1.Divider, { orientation: "left", children: (0, jsx_runtime_1.jsxs)(antd_1.Typography.Title, { level: 4, style: { color: '#0d4f78', margin: 0 }, "data-qa-selector": dataQaSelectors_1.DataQaSelector.highlightsTitle, children: ["Highlights (", highlights.size, ")"] }) }), (0, jsx_runtime_1.jsxs)(antd_1.Row, { justify: 'start', gutter: 20, children: [Array.from(highlights.values()).map(function (item, idx) {
                        return ((0, jsx_runtime_1.jsx)(antd_1.Col, { xs: 12, sm: 8, lg: 6, children: (0, jsx_runtime_1.jsx)(HighlightsItem_1.HighlightsImage, { item: item, index: idx }) }, item.photoId));
                    }), (0, jsx_runtime_1.jsx)(antd_1.Col, { xs: 12, sm: 8, lg: 6, children: (0, jsx_runtime_1.jsx)(AddImage_1.AddImage, {}) })] }), (0, jsx_runtime_1.jsx)(CameraDrawer_1.CameraDrawer, {})] }));
};
exports.PhotoGallery = PhotoGallery;
