// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MzgD2WAvzoOT3lxZ6aaQ {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  margin-bottom: 25px;
}
.keaUTiTYitrZAGfvGlMs {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ffa500;
  border-radius: 5px;
  aspect-ratio: 3/2;
}
`, "",{"version":3,"sources":["webpack://./src/components/AddImage/styles.less"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EAEA,gBAAA;EACA,mBAAA;AADF;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,eAAA;EACA,kBAAA;EAEA,yBAAA;EACA,kBAAA;EACA,iBAAA;AAJF","sourcesContent":["@antVersion: antd-5-13-2;\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n\n  min-height: 100%;\n  margin-bottom: 25px;\n}\n\n.imageWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  cursor: pointer;\n  text-align: center;\n\n  border: 1px solid #ffa500;\n  border-radius: 5px;\n  aspect-ratio: 3/2;\n}\n\n@ant-prefix: antd-5-13-2;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MzgD2WAvzoOT3lxZ6aaQ`,
	"imageWrapper": `keaUTiTYitrZAGfvGlMs`
};
export default ___CSS_LOADER_EXPORT___;
