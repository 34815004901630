"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getServerErrors = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var axios_1 = require("axios");
var getServerErrors = function (error) {
    if (error instanceof axios_1.AxiosError && error.response) {
        return (0, jsx_runtime_1.jsx)("div", { "data-qa-selector": "error-msg", children: error.response.data.error });
    }
    if (error instanceof axios_1.AxiosError && !error.response) {
        return ((0, jsx_runtime_1.jsxs)("div", { "data-qa-selector": "error-msg", children: [error.name, ": ", error.message] }));
    }
    //200 HTTP but no data
    if ('errors' in error) {
        return error.errors.map(function (err, idx) { return ((0, jsx_runtime_1.jsx)("div", { "data-qa-selector": "error-msg", children: err.message }, err.message + idx)); });
    }
    //400 HTTP but no data
    if ('error' in error) {
        return (0, jsx_runtime_1.jsx)("div", { "data-qa-selector": "error-msg", children: error.message });
    }
    //default
    return (0, jsx_runtime_1.jsx)("div", { "data-qa-selector": "error-msg", children: "Unknown error" });
};
exports.getServerErrors = getServerErrors;
