"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetPendingCarsQuery = exports.GetPendingCarsDocument = exports.useGetImagePresignedUrlQuery = exports.GetImagePresignedUrlDocument = exports.useGetCarDetailsQuery = exports.GetCarDetailsDocument = exports.useDeleteCarPhotoMutation = exports.DeleteCarPhotoDocument = exports.ImageType = exports.ImageSubType = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("./api");
exports.ImageSubType = {
    ExteriorFrontLeft: 'EXTERIOR_FRONT_LEFT',
    ExteriorLeftSide: 'EXTERIOR_LEFT_SIDE',
    ExteriorBackLeft: 'EXTERIOR_BACK_LEFT',
    ExteriorRear: 'EXTERIOR_REAR',
    ExteriorRightBack: 'EXTERIOR_RIGHT_BACK',
    ExteriorRightSide: 'EXTERIOR_RIGHT_SIDE',
    ExteriorFrontRight: 'EXTERIOR_FRONT_RIGHT',
    ExteriorFront: 'EXTERIOR_FRONT',
    InteriorDriverSide_1: 'INTERIOR_DRIVER_SIDE_1',
    InteriorDriverSide_2: 'INTERIOR_DRIVER_SIDE_2',
    InteriorFrontCockpitFromRearSeat: 'INTERIOR_FRONT_COCKPIT_FROM_REAR_SEAT',
    InteriorDriverSideFromBackSeat: 'INTERIOR_DRIVER_SIDE_FROM_BACK_SEAT',
    InteriorBackSeatsDriverSide: 'INTERIOR_BACK_SEATS_DRIVER_SIDE',
    InteriorBackSeat: 'INTERIOR_BACK_SEAT',
    InteriorTrunk_1: 'INTERIOR_TRUNK_1',
    InteriorTrunk_2: 'INTERIOR_TRUNK_2',
    Highlights: 'HIGHLIGHTS',
};
exports.ImageType = {
    Interior: 'INTERIOR',
    Exterior: 'EXTERIOR',
    Highlights: 'HIGHLIGHTS',
};
exports.DeleteCarPhotoDocument = "\n    mutation deleteCarPhoto($photoId: UUID!) {\n  deleteCarPhoto(photoId: $photoId)\n}\n    ";
var useDeleteCarPhotoMutation = function (options) {
    return (0, react_query_1.useMutation)(__assign({ mutationKey: ['deleteCarPhoto'], mutationFn: function (variables) {
            return (0, api_1.api)(exports.DeleteCarPhotoDocument, variables)();
        } }, options));
};
exports.useDeleteCarPhotoMutation = useDeleteCarPhotoMutation;
exports.useDeleteCarPhotoMutation.fetcher = function (variables, options) {
    return (0, api_1.api)(exports.DeleteCarPhotoDocument, variables, options);
};
exports.GetCarDetailsDocument = "\n    query getCarDetails($input: CarDetailsInput!) {\n  getCarDetails(input: $input) {\n    adId\n    vin\n    stockNumber\n    make\n    model\n    color\n    gearType\n    builtYear\n    photos {\n      photoId\n      imageType\n      imageSubType\n      originalImageUrl\n      thumbnailImageUrl\n    }\n  }\n}\n    ";
var useGetCarDetailsQuery = function (variables, options) {
    return (0, react_query_1.useQuery)(__assign({ queryKey: ['getCarDetails', variables], queryFn: (0, api_1.api)(exports.GetCarDetailsDocument, variables) }, options));
};
exports.useGetCarDetailsQuery = useGetCarDetailsQuery;
exports.useGetCarDetailsQuery.getKey = function (variables) { return [
    'getCarDetails',
    variables,
]; };
exports.useGetCarDetailsQuery.fetcher = function (variables, options) {
    return (0, api_1.api)(exports.GetCarDetailsDocument, variables, options);
};
exports.GetImagePresignedUrlDocument = "\n    query getImagePresignedUrl($input: PresignedUrlInput!) {\n  getImagePresignedUrl(input: $input) {\n    url\n  }\n}\n    ";
var useGetImagePresignedUrlQuery = function (variables, options) {
    return (0, react_query_1.useQuery)(__assign({ queryKey: ['getImagePresignedUrl', variables], queryFn: (0, api_1.api)(exports.GetImagePresignedUrlDocument, variables) }, options));
};
exports.useGetImagePresignedUrlQuery = useGetImagePresignedUrlQuery;
exports.useGetImagePresignedUrlQuery.getKey = function (variables) { return ['getImagePresignedUrl', variables]; };
exports.useGetImagePresignedUrlQuery.fetcher = function (variables, options) {
    return (0, api_1.api)(exports.GetImagePresignedUrlDocument, variables, options);
};
exports.GetPendingCarsDocument = "\n    query getPendingCars {\n  getPendingCars {\n    vin\n    stockNumber\n    make\n    model\n  }\n}\n    ";
var useGetPendingCarsQuery = function (variables, options) {
    return (0, react_query_1.useQuery)(__assign({ queryKey: variables === undefined
            ? ['getPendingCars']
            : ['getPendingCars', variables], queryFn: (0, api_1.api)(exports.GetPendingCarsDocument, variables) }, options));
};
exports.useGetPendingCarsQuery = useGetPendingCarsQuery;
exports.useGetPendingCarsQuery.getKey = function (variables) {
    return variables === undefined ? ['getPendingCars'] : ['getPendingCars', variables];
};
exports.useGetPendingCarsQuery.fetcher = function (variables, options) {
    return (0, api_1.api)(exports.GetPendingCarsDocument, variables, options);
};
